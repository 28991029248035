.home {
  background-color: $white;

  main {
    padding-top: 0;
    background-color: $white;
  }

  .rich-text .rich-text__content.desktop-position-center {
    @include mobile-screens-down {
      padding: 60px 0;
    }
  }

  header {
    & > div {
      @include tablet-screens-up {
        border-bottom: 1px solid transparent;
      }
    }
  }

  .h2 {
    @include mobile-screens-down {
      br:not(:first-of-type) {
        display: none;
      }
    }
  }
}

#selected-case-study-text {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 20;
  text-align: center;
  color: $white;
  pointer-events: none;
  display: none;

  &.is-active {
    display: flex;
  }

  & > div {
    padding: 0 20px;
    box-sizing: border-box;

    @include mobile-screens-up {
      padding: 0 80px;
    } 
  }
}