.footer {
  grid-area: footer;
  padding: 25px 20px;
  background-color: #000;
  color: $white;
  box-sizing: border-box;

  @include mobile-screens-down {
    padding: 25px 20px 10px;
  }

  @include mobile-screens-up {
    height: 380px;
  }

  &__grid {
    display: grid;

    @include mobile-screens-down {
      grid-template:  "copyright address"
                      "pages address";
      column-gap: 20px;
      row-gap: 0;
    }

    @include mobile-screens-up {
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
    }

    @include tablet-screens-up {
      grid-template-columns: repeat(6, 1fr);
    }

    h5 {
      font-weight: 500;
    }

    a {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 3px;
      }

      &.external-link::after {
        content: " \2197";
      }
    }

    ul {
      line-height: 1.2em;
      margin-bottom: 1em;
    }

    & > div {
      &:nth-child(1) {
        @include mobile-screens-down {
          grid-area: copyright;
        }
      }

      &:nth-child(2) {
        @include mobile-screens-down {
          grid-area: address;
        }

        @include mobile-screens-up {
          grid-column: 3;
        }

        @include tablet-screens-up {
          grid-column: 4;
        }
      }

      &.tablet-down-col {
        @include mobile-screens-down {
          grid-area: pages;
        }

        @include tablet-screens-up {
          display: contents;
        }
      }

      & > div {
        margin-bottom: 1em;
      }
    }
  }

  &__office {
    a[target="_blank"]::after {
      content: " \2197";
    }
  }
}