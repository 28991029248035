.cta-card {
  @include tablet-screens-down {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  &__media,
  &__text,
  & > div {
    margin-bottom: 40px;

    @include tablet-screens-down {
      margin-bottom: 0;
      flex: 1 -1 50%;
      width: 50%;
    }
  }

  &__text {
    @include tablet-screens-down {
      margin-left: 20px;
      font-size: 16px;
    }

    @include tablet-screens-up {
      font-size: get-vw(1512, 22px);
      font-weight: 500;
      line-height: 1.2em;
    }

    @include large-screens-up {
      font-size: 22px;
    }
  }

  &__button {
    @include tablet-screens-up {
      font-size: get-vw(1512, 22px);
      font-weight: 500;
      line-height: 1.2em;
    }

    @include large-screens-up {
      font-size: 22px;
    }
  }
}