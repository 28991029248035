.product-page {
    main {
        background-color: #FAFAF8;
        
        @media (min-width: 990px) {
            padding-top: 54px;
        }

        .product-item {
            background-color: #FAFAF8;
            
            .hero-image {
                margin-top: 20px;
        
                img {
                    width: 100%;
                }
            }
        
            .product-info__wrapper {
                padding: 0 20px 170px 20px;
                margin-top: 20px;
        
                .product-info__header {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    margin-bottom: 50px;
                    
                    h1 {
                        width: 50%;
                        font-size: 25px;
                        font-weight: 500;
                    }
        
                    .product-info__pricing {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
        
                        .product-info__price {
                            font-size: 25px;
                        }
        
                        .product-info__cart {
                            padding: 5px 50px;
                            border-radius: 50px;
                            font-size: 16px;
                            background-color: $black;
                            color: $white;
                            border: 0;
                            outline: none;
        
                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }
        
                .product-info__specs-wrapper {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
        
                    .product-info__awards {
                        width: 50%;
                        padding-top: 10px;
        
                        .product-info__awards-title {
                            font-size: 20px;
                            font-weight: 500;
                        }
                    }
        
                    .product-info__specs {
                        width: 100%;
                        border-top: 1px solid #27272459;
                        padding-top: 10px;
                        display: flex;
                        flex-direction: row;
        
                        .product-info__specs-title {
                            font-size: 20px;
                            font-weight: 500;
                            margin-right: 100px;
                        }
                    }
                }
            }

            .product-back__wrapper {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $default_background;
                padding: 125px 0;
                
                a {
                    padding: 16px 100px;
                    border: 1px solid $black;
                    border-radius: 2px;
                    outline: none;
                    text-decoration: none;
                    font-size: 18px;
                }
            }
        }
    }
}