.featured-entries {
  padding: 20px 0;

  @include mobile-screens-up {
    padding: 20px 0 40px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__row {
    @include mobile-screens-up {
      padding: 0 20px;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 20px;
    }
  }

  &__text {
    grid-column: 1 / span 2;

    @include mobile-screens-down {
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }

  &__content {
    grid-column: 3 / span 4;
    overflow: hidden;

    @include mobile-screens-up {
      margin-left: -10px;
      margin-right: -10px;
    }

    h5 {
      opacity: 0.5;
      margin: 0 0 10px 10px;
    }

    .items-mobile-wrapper {
      @include mobile-screens-down {
        width: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        padding: 0 0 10px;
      }
    }
  }

  &__items {
    display: flex;
    flex-wrap: nowrap;
  }

  &__item {
    flex: 1;
    padding: 0 10px;
    box-sizing: border-box;

    @include mobile-screens-down {
      float: left;
      min-width: 65.6%;

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }

      .card__text.c1 {
        font-size: 16px;
      }
    }

    @include mobile-screens-up {
      max-width: 50%;

      &--has-border {
        border-left: $border_thickness solid $black;
      }
    }
  }

  &__buttons {
    margin-top: 40px;

    @include mobile-screens-up {
      margin-top: 80px;
    }
  }
}