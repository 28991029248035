.newsletter-cta {
  display: flex;
  align-items: center;
  padding: 40px 20px;

  @include tablet-screens-down {
    flex-direction: column;
  }

  @include tablet-screens-up {
    height: 550px;
    justify-content: space-around;
    padding: 20px;
  }

  &--has-border {
    border-top: $border_thickness solid $black;
  }

  &__media {
    margin-top: -30px;

    @include tablet-screens-down {
      width: 300px;
      order: 2;
    }

    @include tablet-screens-up {
      width: 530px;
    }

    img {
      mix-blend-mode: multiply;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include tablet-screens-down {
      order: 1;
      margin-bottom: 40px;
    }
  }

  &__heading {
    margin-bottom: 30px;
  }

  &__copy {
    margin-bottom: 30px;

    @include mobile-screens-down {
      br {
        display: none;
      }
    }

    @include tablet-screens-up {
      margin-bottom: 60px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}