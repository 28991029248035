.nav {
  &__list {
    @include tablet-screens-down {
      text-align: center;
      font-size: 55px;
      font-weight: 500;
      line-height: 1.2em;
      margin-top: -40px;
      transform: scale(1.1);
      transition: transform 0.4s ease;

      .nav-is-open & {
        transform: scale(1.0);
      }
      
      .link {
        body:not(.home) & {
          opacity: 0.5;
          transition: opacity 0.4s ease;
        }

        &.is-active,
        .no-touchevents &:hover {
          text-decoration: none;
          opacity: 1 !important;
        }
      }
    }

    @include tablet-screens-up {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 500px;
    }

    @include mobile-screens-down {
      line-height: 1.1em;
    }
  }

  @include tablet-screens-down {
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s ease;

    .nav-is-open & {
      opacity: 1;
      pointer-events: all;
    }
  }
}