.article {
  padding: 60px 20px 0;

  @include mobile-screens-up {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 20px;
    padding: 100px 20px 0;
  }

  hr {
    margin: 0 -20px;
    background-color: $grey_rule;
  }

  &__header {
    grid-column: 1 / span 6;
    display: contents;

    hr {
      grid-column: 1 / span 6;
    }
  }

  &__title {
    font-weight: 500;
    font-size: get-vw(375, 26px);
    line-height: 1.2em;
    grid-column: 3 / span 4;
    margin-bottom: 60px;

    @include mobile-screens-down {
      text-align: center;
    }

    @include mobile-screens-up {
      font-size: get-vw(1512, 64px);
      margin-bottom: 20px;
      padding-right: 60px;
    }
  }

  &__aside {
    grid-column: 1 / span 2;
    padding: 20px 0;

    @include mobile-screens-up {
      border-right: $border_thickness solid $grey_rule;
    }
  }

  &__content {
    grid-column: 3 / span 4;
    padding: 20px 0;

    &__grid {
      @include mobile-screens-up {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 20px;
      }

      & > div {
        grid-column: 1 / span 3;
        max-width: 726px;
      }
    }
  }

  &__container {
    grid-column: 1 / span 6;
    display: contents;
  }

  &__thumb {
    margin-bottom: 20px;

    hr {
      margin-top: 20px;
    }
  }

  &__footer {
    display: contents;

    @include mobile-screens-down {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    hr {
      grid-column: 1 / span 6;

      @include mobile-screens-down {
        flex: 1 0 100%;
      }
    }
  }

  &__pullquote {
    padding: 60px 0;
    background-color: #EBE9F8;

    @include mobile-screens-up {
      padding: 120px 0;
      transform: translateX(-30%);
    }
  }

  &__media {
    margin: 20px 0;

    @include mobile-screens-up {
      margin: 40px 0;

      &--2 {
        margin-top: -20px;
      }
    }

    &--offset {
      @include mobile-screens-up {
        transform: translateX(-30%);
      }
    }
  }

  &__button {
    @include mobile-screens-down {
      flex: 1 0 50%;
      box-sizing: border-box;
    }

    &.button {
      border: 0;
    }

    &--previous {
      grid-column: 1 / span 2;

      @include mobile-screens-up {
        margin-left: -20px;
      }

      &.button {
        @include mobile-screens-up {
          border-right: $border_thickness solid $grey_rule;
        }
      }
    }

    &--next {
      grid-column: 5 / span 2;

      @include mobile-screens-up {
        margin-right: -20px;
      }

      &.button {
        border-left: $border_thickness solid $grey_rule;
      }
    }
  }
}