@font-face {
  font-family: 'PostGrotesk';
  src: url('../../fonts/PostGrotesk-Bold.woff2') format('woff2'),
       url('../../fonts/PostGrotesk-Bold.woff') format('woff'),
       url('../../fonts/PostGrotesk-Bold.eot') format('eot');
  font-weight: 700;
}

@font-face {
  font-family: 'PostGrotesk';
  src: url('../../fonts/PostGrotesk-Book.woff2') format('woff2'),
       url('../../fonts/PostGrotesk-Book.woff') format('woff'),
       url('../../fonts/PostGrotesk-Book.eot') format('eot');
  font-weight: 400;
}

@font-face {
  font-family: 'PostGrotesk';
  src: url('../../fonts/PostGrotesk-Medium.woff2') format('woff2'),
       url('../../fonts/PostGrotesk-Medium.woff') format('woff'),
       url('../../fonts/PostGrotesk-Medium.eot') format('eot');
  font-weight: 500;
}

@font-face {
  font-family: 'Arnhem';
  src: url('../../fonts/Arnhem-Blond.woff2') format('woff2'),
       url('../../fonts/Arnhem-Blond.woff') format('woff');
}