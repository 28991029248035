.arrow-icon {
  width: 18px;
  margin-left: 10px;
  overflow: visible;

  @include mobile-screens-down {
    width: 14px;
  }

  path {
    transition: transform .4s ease;
  }

  &__head {
    transform: translateX(0);
  }

  &--rotated {
    transform: rotate(180deg);
    margin-right: 10px;
    margin-left: 0;
  }
}