.clock {
  width: 80px;
  height: 80px;
  position: relative;
  padding: 6px;
  border-radius: 50%;
  background-color: $white;
  margin: 0 20px;

  @include tablet-screens-up {
    width: 200px;
    height: 200px;
    padding: 6px;
  }

  .outer-clock-face {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 100%;

    &::after {
      transform: rotate(90deg);
    }

    &::after,
    &::before,
    & .marking {
      content: '';
      position: absolute;
      width: .25px;
      height: 100%;
      background: $grey_rule;
      z-index: 0;
      left: 50%;
    }

    .marking {
      background: $grey_rule;
      width: .25px;

      @for $i from 1 through 29 {
        &.marking-#{$i} {
          transform: rotate(#{$i * 6}deg);
        }
      }
    }
  }

  .inner-clock-face {
    position: absolute;
    top: 12%;
    left: 12%;
    width: 76%;
    height: 76%;
    background: $white;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    z-index: 1;

    @include tablet-screens-up {
      top: 8%;
      left: 8%;
      width: 84%;
      height: 84%;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 5px;
      height: 5px;
      border-radius: 5px;
      margin-left: -2.5px;
      margin-top: -2.5px;
      background: $black;
      z-index: 11;

      @include tablet-screens-up {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        margin-left: -5px;
        margin-top: -5px;
      }
    }
  }

  .hand {
    width: 50%;
    height: 1px;
    right: 50%;
    top: 50%;
    background: $black;
    position: absolute;
    border-radius: 2px;
    transform-origin: 100%;
    transform: rotate(90deg);
    transition-timing-function: cubic-bezier(0.1, 2.7, 0.58, 1);

    @include tablet-screens-up {
      height: 2px;
    }

    &.hour-hand {
      width: 33%;
      z-index: 3;
    }

    &.min-hand {
      height: 1px;
      z-index: 4;
      width: 43%;

      @include tablet-screens-up {
        height: 2px;
      }
    }

    &.second-hand {
    background: #ee791a;
      width: 45%;
      height: 1px;
      z-index: 5;
    }
  }

  .pin {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 2px;
    border-radius: 2px;
    margin-left: -1px;
    margin-top: -1px;
    background: $white;
    z-index: 11;

    @include tablet-screens-up {
      width: 3px;
      height: 3px;
      border-radius: 3px;
      margin-left: -1.5px;
      margin-top: -1.5px;
    }
  }

  &--dark {
    background-color: $black;

    .inner-clock-face {
      background: $black;

      &::before {
        background: $white;
      }
    }

    .outer-clock-face {
      &::after,
      &::before,
      & .marking {
        background: gray;
      }
    }

    .hand {
      background: $white;

      &::before {
        background: $white;
      }
    }

    .pin {
      background: $black;
    }
  }
}