.featured-item {
  text-align: center;
  color: $white;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__media {
    position: absolute;
    z-index: 0;
    background-color: $black;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      opacity: 0.8;
    }
  }

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__text {
    margin-top: 140px;

    h2 {
      margin-bottom: 60px;
    }
  }

  &__cta {
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    color: $black;

    a {
      width: 388px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $white;
      transition: all .4s ease;

      &:hover {
        background-color: $black;
        color: $white;
      }
    }
  }
}