.shop-navigation {
    display: grid;
    grid-template-columns: 1.95fr 500px;
    padding: 0 20px;
    margin-top: 20px;

    .shop-logo {
        span {
            font-size: 20px;
            font-weight: 500;
        }
    }

    .shop-nav {
        .cart-title {
            font-size: 20px;
            font-weight: 500;
            margin-right: 45px;

            sup {
                vertical-align: super;
                font-size: 18px;
                font-weight: 500;
            }
        }

        a {
            font-size: 20px;
            font-weight: 400;
        }
    }
}

.shop-filters {
    margin-top: 20px;
    padding: 0 20px;

    ul {
        padding: 0;
        margin: 0;

        li {
            font-size: 20px;
            font-weight: 400;

            &:not(:last-child) {
                margin-bottom: 5px;
            }

            &.filter-active {
                text-decoration: underline;
            }
        }
    }
}

.product-list {
    padding: 0 20px;
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 20px;

    .product-list__entry figcaption p {
        font-size: 20px;
        font-weight: 400;

        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }
}

.product-list__load-more__wrapper {
    margin-top: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 100px;

    .product-list__load-more {
        border: 1px solid $black;
        padding: 16px 120px;
        border-radius: 2px;
        font-size: 18px;
        font-weight: 400;
        background: none;
        outline: none;

        &:hover {
            cursor: pointer;
        }
    }
}