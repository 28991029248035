.fixed-nav {
  position: sticky;
  top: 160px;
  padding: 0 20px 200px;
  overflow: visible;
  height: 0;
  z-index: 7777;
  pointer-events: none;

  @include tablet-screens-down {
    display: none;
  }

  &__list {
    display: inline-block;
    pointer-events: all;
  }

  &__item {
    opacity: 0.35;
    transition: opacity .4s ease;

    &.is-active,
    &:hover {
      opacity: 1;
    }
  }
}