.featured-article {
  display: flex;
  flex-wrap: wrap;

  @include mobile-screens-up {
    align-items: stretch;
  }

  hr {
    flex: 1 0 100%;
    height: $border_thickness;
    background-color: rgba(0,0,0,0.2);
    width: 100%;
    margin: 0 0px 20px;

    @include mobile-screens-down {
      display: none;
    }
  }

  &__media,
  &__text {
    box-sizing: border-box;
  }

  &__media {
    @include mobile-screens-down {
      width: 100%;
      order: 1;
      margin-bottom: 20px;
    }

    .aspect-ratio-wrapper {
      padding-bottom: 66.6%;
    }

    @include mobile-screens-up {
      padding-left: 20px;
      flex: 1;
    }

    @include tablet-screens-up {
      padding-left: 10px;
      flex: 2;

      .aspect-ratio-wrapper {
        padding-bottom: 54.5641025641%;
      }
    }
  }

  &__text {
    flex: 1;

    @include mobile-screens-down {
      order: 2;
    }

    @include mobile-screens-up {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & > div {
        padding-right: 20px;
      }
    }
  }

  &__title {
    margin-bottom: 0.5em;
  }

  &__pullquote {
    @include mobile-screens-down {
      margin-bottom: 0.75em;
    }
  }
}