.article-text-block {
  margin: 20px;

  @include mobile-screens-up {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 20px;
    margin: 0;
    padding: 0 20px;

    & > div {
      grid-column: 5 / span 6;
    }
  }

  .rte.serif {
    strong,
    h4 {
      @extend .h4;
      font-family: $font_sans;
    }
  }

  &__buttons {
    padding: 20px 0 0;
    border-top: $border_thickness solid $grey_rule;
    display: flex;

    @include mobile-screens-up {
      padding: 40px 0;
    }

    .button {
      flex: 1;
      text-decoration: none;
  
      &:first-child {
        margin-right: 20px;
      }
    }
  }

  &--borders {
    & > div {
      border-top: $border_thickness solid $grey_rule;
      padding: 20px 0;
    }
  }
}