.case-study-info {
  padding: 14px 10px 10px;
  background-color: $white;
  box-sizing: border-box;

  @include mobile-screens-down {
    .c1, .h4 {
      font-size: 16px;
    }
  }

  @include tablet-screens-up {
    padding: 14px 10px 40px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
  }

  &__row {
    &:first-child {
      margin-bottom: 40px;
    }

    @include tablet-screens-up {
      display: flex;
      align-items: stretch;

      &:first-child {
        margin-bottom: 60px;
      }
    }

    & > div {
      flex: 1;
      padding: 0 10px;
      box-sizing: border-box;
    }
  }

  &__details {
    border-top: $border_thickness solid $grey_rule;
    padding: 10px 0 20px;
    
    .flex .h4 {
      margin-right: 0.5em;
    }
  }

  &__details-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 20px;
    align-items: baseline;

    &__col:first-child {
      grid-column: span 2;
    }

    &__col:last-child {
      grid-column: span 4;
    }
  }

  &__awards {
    padding: 10px 0 20px;
  }

  &__description > div {
    max-width: 640px;

    @include mobile-screens-down {
      br {
        display: none;
      }
    }
  }
}