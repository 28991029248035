html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  background-color: $default_background;
}

::selection {
  background-color: $black;
  color: $white;
}

body {
  font-family: $font_sans;
  margin: 0;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $default_background;
  color: $black;
  width: 100%;

  @include tablet-screens-up {
    padding-bottom: 708px;
  }
}

main {
  padding-top: 50px;
  position: relative;
  z-index: 10;
  background-color: $default_background;

  @include tablet-screens-up {
    padding-top: 53px;
  }
}

.layer-2 {
  @include tablet-screens-up {
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

em {
  font-style: italic;
}

a {
  color: inherit;
  text-decoration: none;
}

hr {
  margin: 0 20px;
  height: $border_thickness;
  background-color: $black;
  border: 0;
}

section {
  position: relative;
}

.process-section {
  padding: 20px 0 20px;

  @include tablet-screens-up {
    padding: 60px 0 40px;
  }
}

figure {
  figcaption {
    margin-top: 10px;
  }
}

.lazyload,
.lazyloading {
	opacity: 0;
  backdrop-filter: filter(20px);
}

.lazyloaded {
	opacity: 1;
  backdrop-filter: filter(0px);
	transition: all 1s ease;
}

video {
  width: 100%;
  opacity: 1;
  transition: opacity 1s ease;

  &.lazy {
    opacity: 0;
  }
}

[data-component="inView"] {
  opacity: 0;
  transition: opacity 1s ease;

  &.in-view {
    opacity: 1;
  }
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  word-wrap: normal !important;
}

.aspect-ratio-wrapper {
  position: relative;

  img,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.buttons {
  margin: 60px 0 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  padding: 0 20px;

  @include mobile-screens-up {
    gap: 20px;
  }

  .button {
    grid-column: 3 / span 2;

    @include mobile-screens-down {
      grid-column: 2 / span 4;
    }
  }

  &--align-right {
    .button {
      @include mobile-screens-up {
        grid-column: 5 / span 2;
      }
    }
  }
}

.section-title {
  padding: 0 20px 20px;

  @include mobile-screens-down {
    padding: 20px 20px 10px;
  }

  &--has-padding {
    padding: 20px 20px 0;
    margin: 0 0 20px;

    @include mobile-screens-down {
      padding: 20px 20px 0;
      margin: 0 0 10px;
    }
  }

  &:not(&--black) {
    opacity: 0.5;
  }
}

.link {
  &.is-active,
  .no-touchevents &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-thickness: $border_thickness;
    text-underline-offset: 3px;
  }

  .touchevents &:hover {
    opacity: 1;
  }
}

.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &.desktop-3-col:not(&--articles) {
    @include mobile-screens-up {
      padding: 0 15px;
    }

    .grid-item {
      margin-bottom: 30px;
      box-sizing: border-box;

      @include mobile-screens-up {
        margin-bottom: 20px;
      }

      &:not(&--insert) {
        flex: 1 0 100%;
        max-width: 100%;

        @include mobile-screens-up {
          padding: 0 5px;
          flex: 1 0 50%;
          max-width: 50%;
        }

        @include tablet-screens-up {
          flex: 1 0 33.333%;
          max-width: 33.333%;
        }
      }
    }

    .card__text {
      @include mobile-screens-down {
        margin: 0 20px;
      }
    }
  }

  &.desktop-2-col {
    padding: 0 10px;

    @include mobile-screens-down {
      padding: 0 15px;
    }

    @include mobile-screens-up {
      margin-bottom: 80px;
    }

    .grid-item {
      padding: 0 10px;
      margin-bottom: 30px;
      flex: 1 0 50%;
      box-sizing: border-box;
      max-width: 50%;

      @include mobile-screens-down {
        padding: 0 5px;
      }
    }

    @include mobile-screens-down {
      .card__media .aspect-ratio-wrapper {
        padding-bottom: 133.9743589744% !important;
      }

      .card__tagline {
        display: none;
      }
    }
  }

  &--articles {
    @include mobile-screens-up {
      padding: 0 10px;
    }

    .grid-item {
      box-sizing: border-box;

      @include mobile-screens-down {
        padding: 0 20px;
      }

      &:not(&--featured-item) {
        flex: 1 0 100%;
        max-width: 100%;
        margin-bottom: 30px;

        @include mobile-screens-up {
          padding: 0 10px;
          flex: 1 0 50%;
          max-width: 50%;
          margin-bottom: 20px;
        }

        @include tablet-screens-up {
          flex: 1 0 33.333%;
          max-width: 33.333%;
        }
      }

      &--featured-item {
        margin-bottom: 30px;

        @include mobile-screens-up {
          margin-bottom: 40px;
          padding: 0 10px;
        }
      }
      
      .card {
        // border-bottom: $border_thickness solid rgba(0,0,0,0.2);

        @include mobile-screens-up {
          padding: 0 0 40px;
        }

        @include tablet-screens-up {
          padding: 0 0 80px;
        }
      }
    }
  }
}

.grid-item {
  @for $i from 1 through 100 {
    &:nth-child(#{$i}) {
      order: $i;
    }
  }

  &--insert {
    flex: 1 0 100%;

    @include mobile-screens-up {
      margin: 0 -15px;
    }
  }

  &--featured-item {
    flex: 1 0 100%;
  }
  
  @for $i from 1 through 100 {
    &.desktop-insert-position-#{$i} {
      @include desktop-screens-up {
        order: $i - 1;
      }
    }
  }

  @for $i from 1 through 100 {
    &.tablet-insert-position-#{$i} {
      @include tablet-screens-only {
        order: $i - 1;
      }
    }
  }

  @for $i from 1 through 100 {
    &.mobile-insert-position-#{$i} {
      @include mobile-screens-down {
        order: $i - 1;
      }
    }
  }
}

.flex {
  display: flex;

  &--mobile-screens-up {
    @include mobile-screens-down {
      display: block;
      margin-bottom: 0.5em;
    }
  }

  &--reverse {
    flex-direction: row-reverse;
  }

  &--align-center {
    align-items: center;
  }

  &.margin {
    padding: 0 10px;
  }

  &.has-rule-separators {
    .flex-item {
      box-sizing: border-box;

      &:nth-child(2) {
        border-left: 1px solid $black;
        border-right: 1px solid $black;
      }
    }
  }
}

.flex-item {
  &.flex-1 {
    flex: 1;
  }

  &.flex-2 {
    flex: 1;

    @include mobile-screens-up {
      flex: 2;
    }
  }

  &.has-gutter {
    padding: 0 10px;
  }
}

.rte {
  h4 {
    font-weight: 500;
    margin-bottom: .5em;
  }

  p:not(:last-child) {
    margin-bottom: 1em;
  }

  figure {
    padding-left: 100px;
    box-sizing: border-box;
    margin: 80px 0;
  }

  blockquote {
    font-weight: 500;
    margin-bottom: 0.25em;
  }

  figcaption {
    font-weight: 400;
    @extend .h4;
  }

  strong {
    font-weight: 500;
  }

  a {
    text-decoration: underline;
    text-decoration-thickness: $border_thickness;
    text-underline-offset: 1px;

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }

  ul, 
  ol {
    margin-bottom: 1em;

    li {
      position: relative;
      padding-left: 1.3em;

      &::before {
        content: "— ";
        position: absolute;
        left: 0;
      }
    }
  }

  code {
    @extend .h4;
  }

  u {
    text-decoration: underline;
    text-decoration-thickness: $border_thickness;
    text-underline-offset: 2px;
  }
}

.center {
  text-align: center;
}

/* carousel height */
.swiper {
  width: 100%;
  height: 600px;

  @include tablet-screens-up {
    height: 600px;
  }
}

.swiper-slide {
  padding-left: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include tablet-screens-up {
    padding-left: 50px;
  }

  img {
    max-height: 500px;

    @include tablet-screens-up {
      max-height: 500px;
    }
  }
}

.multiply,
.multiply img {
  mix-blend-mode: multiply;
}

.content-wrapper {
  padding: 20px 0 40px;

  @include tablet-screens-up {
    padding: 40px 0;
  }
}

.padding-bottom {
  padding-bottom: 60px;
}

.tablet-screens-up {
  @include tablet-screens-down {
    display: none !important;
  }
}

.tablet-screens-down {
  @include tablet-screens-up {
    display: none !important;
  }
}

.caption {
  margin-top: 5px;
}