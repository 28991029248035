.button {
  border: $border_thickness solid $black;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile-screens-down {
    &.c1,
    &.h4 {
      font-size: 18px;
    }
  }

  @include mobile-screens-up {
    height: 60px;
  }

  &--secondary {
    width: 100%;

    @include mobile-screens-up {
      height: 50px;
      border-radius: 2px;
    }
  }

  &--white {
    background-color: $white;
    border-color: $white;
  }

  .no-touchevents &:hover,
  .touchevents &:active {
    color: $white;
    background-color: $black;
    border-color: $black;
    cursor: pointer;

    .arrow-icon__head {
      transform: translateX(65%);
    }

    .arrow-icon path {
      fill: $white;
    }
  }
}