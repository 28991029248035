.article-media-block {
  margin: 20px;

  iframe {
    max-width: 100%;
  }
  
  @include mobile-screens-up {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 20px;
    padding: 0 20px;
    margin: 40px 0;

    & > div {
      grid-column: 5 / span 6;
    }
  }
}