.rich-text {
  text-align: center;

  @include tablet-screens-down {
    padding: 0 20px;
  }

  &__content {
    padding: 0 0;
    width: 100%;
    box-sizing: border-box;

    @include mobile-screens-up {
      padding: 0 20px 40px;
    }

    @media screen and (min-width: 840px) {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 20px;
    }

    &.desktop-position-center {
      padding: 60px 0;
      justify-content: center;

      @include mobile-screens-up {
        padding: 120px 0;

        &.text-size-h1 {
          padding: get-vw(1512, 250px) 0;
        }
      }

      .rich-text__text {
        grid-column: 1 / span 6;

        @include mobile-screens-up {
          grid-column: 2 / span 4;
        }
      }

      .rich-text__buttons {
        margin-top: 30px;
        grid-column: 1 / span 6;
      }
    }

    &.desktop-position-right {
      text-align: left;

      @include mobile-screens-down {
        margin-bottom: 20px;
      }

      @media screen and (min-width: 840px) {
        padding: 0 20px;
        margin: 20px 0;
      }

      .rich-text__text {
        grid-column: 4 / span 3;
      }

      .rich-text__heading {
        grid-column: 1 / span 3;
      }
    }

    &.desktop-position-left {
      text-align: left;

      @include mobile-screens-down {
        margin-bottom: 20px;
      }

      @media screen and (min-width: 840px) {
        padding: 0 20px;
        margin: 20px 0;
      }

      .rich-text__text {
        grid-column: 1 / span 3;
      }

      .rich-text__heading {
        display: none;
      }
    }

    &--has-media {
      grid-template-rows: 60px auto;

      .rich-text__heading {
        grid-row: 1;
      }

      .rich-text__media {
        grid-column: 1 / span 3;
        grid-row: 2;
        padding-top: 40px;
      }

      .rich-text__text {
        grid-column: 4 / span 3;
        grid-row: 1 / span 2;
      }
    }
  }

  &__text {
    p:not(:last-child),
    h4:not(:last-child) {
      margin-bottom: 1em;
    }

    blockquote {
      @extend .h2;
      margin-bottom: 1em;
    }
  }
}