.news-feed {
  margin: 0 20px;
  border-top: $border_thickness solid $grey_rule;
  display: flex;
  flex-direction: column;

  &__item {
    display: block;
    border-bottom: $border_thickness solid $grey_rule;
    padding: 10px 0;

    @include mobile-screens-down {
      .c2 {
        font-size: 16px;
      }
    }

    @include mobile-screens-up {
      display: flex;
      align-items: stretch;
      padding: 20px 0;
    }

    & > div {
      flex: 1;

      &:last-child {
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile-screens-up {
          justify-content: flex-end;
        }
      }
    }

    &__image {
      max-width: 150px;

      @include tablet-screens-down {
        margin-top: 10px;
      }

      @include tablet-screens-up {
        max-width: 240px;
      }
    }

    .c2 {
      opacity: .5;
      margin-bottom: 5px;

      @include mobile-screens-up {
        margin-bottom: 10px;
      }
    }

    .no-touchevents &:hover {
      .h3 {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-thickness: $border_thickness;
        text-underline-offset: 3px;
      }
    }
  }

  &__li {
    flex: 1;

    &--insert {
      margin: -1px -20px 0;
    }

    @for $i from 1 through 100 {
      &.insert-position-#{$i} {
        order: $i - 1;
      }
    }

    hr {
      background-color: $grey_rule;
    }

    &:nth-last-child(2) {
      .news-feed__item {
        border-bottom: 0;
      }
    }
  }
}