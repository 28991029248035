.media-block {
  &__content {
    padding: 0 10px;

    @include mobile-screens-down {
      padding: 0 10px 0;
      margin-bottom: 20px;
    }

    @include mobile-screens-up {
      display: flex;

      &.desktop-position-center {
        justify-content: center;
  
        &.media-count-1 {
          .media-block__item {
            max-width: 971px;
    
            &.placeholder {
              max-width: 750px;
            }
          }
        }
      }
    
      &.desktop-position-right {
        justify-content: flex-end;
  
        .media-block__item {
          max-width: 50%;
        }
      }
    
      &.desktop-position-left {
        justify-content: flex-start;
  
        .media-block__item {
          max-width: 50%;
        }
      }
    }
  }

  &__item {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;

    @include mobile-screens-down {
      &:last-child {
        margin-top: 20px;
      }
    }
  }
}