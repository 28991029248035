.h1 {
  font-size: get-vw(375, 42px);
  line-height: 1em;
  letter-spacing: -0.03em;
  font-weight: 500;

  @include mobile-screens-down {
    br {
      display: none;
    }
  }

  @include mobile-screens-up {
    font-size: get-vw(1440, 76px);
  }

  @include desktop-screens-up {
    font-size: 76px;
  }
}

.h2 {
  font-size: 24px;
  line-height: 1.2em;
  font-weight: 500;

  @include mobile-screens-down {
    br {
      display: none;
    }
  }

  @include mobile-screens-up {
    font-size: get-vw(1512, 40px);
  }

  @include large-screens-up {
    font-size: 40px;
  }
}

.h3 {
  font-size: 20px;
  line-height: 1.2em;
  font-weight: 500;

  @include mobile-screens-up {
    font-size: 24px;
  }

  @include desktop-screens-up {
    font-size: 30px;
  }
}

.h4 {
  font-size: 20px;
  line-height: 1.2em;
  font-weight: 500;

  @include desktop-screens-up {
    font-size: 22px;
  }
}

.b1 {
  font-size: 20px;
  line-height: 1.2em;
  letter-spacing: 0;

  @include tablet-screens-up {
    font-size: 24px;
  }

  @include desktop-screens-up {
    font-size: 30px;
  }
}

.b1a {
  font-size: 24px;
  line-height: 1.2em;

  @include desktop-screens-up {
    font-size: 30px;
  }
}

.b2 {
  font-size: 20px;
  line-height: 1.2em;
  font-weight: 400;

  @include mobile-screens-up {
    font-size: 24px;
  }

  @include desktop-screens-up {
    font-size: 30px;
  }
}

.c1 {
  font-size: 20px;
  line-height: 1.15em;
  font-weight: 400;

  @include desktop-screens-up {
    font-size: 22px;
  }
}

.c2 {
  font-size: 20px;
  line-height: 1.2em;
  font-weight: 500;

  @include desktop-screens-up {
    font-size: 20px;
  }
}

.c3 {
  font-size: 16px;
  line-height: 1.2em;
  font-weight: 400;

  .c3--medium {
    font-weight: 500;
  }
}

.serif {
  p,
  ul,
  ol,
  &.h2 {
    font-family: $font_serif;
  }
}
