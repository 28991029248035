.sign-up-form {
  @include mobile-screens-down {
    width: calc(100% - 40px);
  }

  &__input {
    display: flex;
    align-items: stretch;
    height: 60px;

    @include mobile-screens-down {
      height: 44px;
      width: 100%;
    }

    &__text-field,
    &__submit {
      outline: 0;
    }

    &__text-field {
      -webkit-appearance: none;
      border: 0;
      padding: 0 15px;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      font-family: $font_sans;
      background-color: $white;
      width: 100%;

      @include mobile-screens-down {
        font-size: 16px;
      }

      @include tablet-screens-up {
        width: 320px;
      }

      &::placeholder {
        color: $black;
      }
    }

    &__submit {
      font-family: $font_sans;
      cursor: pointer;
      padding: 0 15px;
      -webkit-appearance: none;
      border: 0;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      background-color: $black;
      color: $white;
      font-weight: 400;

      @include tablet-screens-down {
        span {
          display: none;
        }

        .arrow-icon {
          margin: 0;
        }
      }

      @include tablet-screens-up {
        min-width: 230px;
      }

      .arrow-icon path {
        fill: $white;
      }

      &:hover {
        cursor: pointer;
    
        .arrow-icon__head {
          transform: translateX(75%);
        }
      }
    }
  }
}