.expertise {
  @include mobile-screens-down {
    .rich-text__content.desktop-position-center {
      padding: 80px 0 60px;
    }
  }
}

.strategy,
.brand,
.digital,
.spatial,
.wayfinding,
.content {
  .media-block__content.desktop-position-center .media-block__item {
    max-width: 600px;
    padding-top: 0;

    @include mobile-screens-down {
      paddin-top: 0;
    }
  }

  @include tablet-screens-up {
    .content-blocks {
      margin-top: -200px;
    }
  }
}

.strategy {
  background-color: #e4efed;

  @include tablet-screens-down {
    .header h1 {
      background-color: rgba(228, 239, 237, 0.5);
    }
  }

  @include tablet-screens-up {
    .header {
      background-color: rgba(228, 239, 237, 0.5);
    }
  }

  main {
    background-color: #e4efed;
  }
}

.brand {
  background-color: #f2f0e0;

  @include tablet-screens-down {
    .header h1 {
      background-color: rgba(242, 240, 224, 0.5);
    }
  }

  @include tablet-screens-up {
    .header {
      background-color: rgba(242, 240, 224, 0.5);
    }
  }

  main {
    background-color: #f2f0e0;
  }
}

.digital {
  background-color: #f2ddd6;

  @include tablet-screens-down {
    .header h1 {
      background-color: rgba(242, 221, 214, 0.5);
    }
  }

  @include tablet-screens-up {
    .header {
      background-color: rgba(242, 221, 214, 0.5);
    }
  }

  main {
    background-color: #f2ddd6;
  }
}

.spatial {
  background-color: #daf2e2;

  @include tablet-screens-down {
    .header h1 {
      background-color: rgba(218, 242, 226, 0.5);
    }
  }

  @include tablet-screens-up {
    .header {
      background-color: rgba(218, 242, 226, 0.5);
    }
  }

  main {
    background-color: #daf2e2;
  }
}

.wayfinding {
  background-color: #eee5e5;

  @include tablet-screens-down {
    .header h1 {
      background-color: rgba(238, 229, 229, 0.5);
    }
  }

  @include tablet-screens-up {
    .header {
      background-color: rgba(238, 229, 229, 0.5);
    }
  }

  main {
    background-color: #eee5e5;
  }
}

.content {
  background-color: #ebe9f8;

  @include tablet-screens-down {
    .header h1 {
      background-color: rgba(235, 233, 248, 0.5);
    }
  }

  @include tablet-screens-up {
    .header {
      background-color: rgba(235, 233, 248, 0.5);
    }
  }

  main {
    background-color: #ebe9f8;
  }
}