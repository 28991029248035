.block-list {
  padding: 80px 20px 100px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;

  &__inner {
    grid-column: 2 / span 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__text {
    margin-bottom: 40px;
  }

  &__list {
    border-top: $border_thickness solid $grey_rule;
    width: 100%;

    li {
      box-sizing: border-box;
      padding: 40px;
      border-bottom: $border_thickness solid $grey_rule;
      text-align: center;
    }
  }
}