.article-info {
  &__row {
    padding: 0 20px;

    @include mobile-screens-down {
      display: flex;
      flex-direction: column;
    }

    @include mobile-screens-up {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      column-gap: 20px;
      padding: 10px 20px 0;

      &:first-child {
        margin-bottom: 100px;
      }
    }

    & > div {
      border-top: $border_thickness solid $grey_rule;
      padding: 10px 0;

      &:first-child {
        grid-column: 1 / span 4;
      }

      &:last-child {
        grid-column: 5 / span 8;

        h1,
        .article-info__description {
          max-width: 725px;
        }
      }
    }

    .article-info__meta {
      @include mobile-screens-down {
        order: 2;
      }
    }

    .article-info__title-container {
      @include mobile-screens-down {
        border-top: $border_thickness solid transparent;
        order: 1;
        margin: 10px 0 20px;
      }
    }

    .article-info__description-container {
      @include mobile-screens-down {
        border-top: $border_thickness solid transparent;
      }

      @include mobile-screens-up {
        margin-bottom: 40px;
      }
    }
  }

  &__credits {
    padding-bottom: 50px;

    li {
      display: flex;

      h4,
      p {
        flex: 1 0 50%;
      }
    }
  }

  &__title {
    @extend .serif;
  }

  &__share-icons {
    margin: 10px 0 20px;

    @include mobile-screens-up {
      margin: 10px 0 0;
    }

    li {
      display: inline-block;
      margin-right: 10px;
    }
  }
}