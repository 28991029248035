.offices {
  padding: 40px 0;

  @include mobile-screens-down {
    padding: 20px 0;

    .flex {
      display: block;
    }
  }
}

.studio {
  .rich-text .rich-text__content.desktop-position-center {
    @include mobile-screens-down {
      padding: 60px 0;
    }
  }

  section {
    @include mobile-screens-down {
      padding: 20px 0;
    }
  }
}