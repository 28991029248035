.thinking,
.opinionArticle {
  background-color: $purple;

  main {
    background-color: $purple;
  }

  @include tablet-screens-down {
    .header h1 {
      background-color: rgba(235, 233, 248, 0.4);
    }
  }

  @include tablet-screens-up {
    .header {
      background-color: rgba(235, 233, 248, 0.4);
    }
  }

  .rich-text .rich-text__content.desktop-position-center {
    @include mobile-screens-down {
      padding: 60px 0;
    }
  }
}

.opinionArticle {
  main {
    padding-top: 0;
  }

  header {
    & > div {
      @include tablet-screens-up {
        border-bottom: 1px solid transparent;
      }
    }
  }
}