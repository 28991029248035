// Colours
$black: #272724;
$white: #FAFAF8;
$grey_rule: rgba(0,0,0,0.2);
$default_background:#F2F2ED;
$purple: #EBE9F8;
$pink: #FBF1F6;

// Borders
$border_thickness: 1px;

// Typography
$font_sans: 'PostGrotesk', helvetica, sans-serif;
$font_serif: 'Arnhem', serif;

// Breakpoints 
$breakpoint_XL: 1440;
$breakpoint_L: 1200;
$breakpoint_M: 990;
$breakpoint_S: 750;