.office-card {
  @include mobile-screens-down {
    margin-bottom: 20px;
  }

  &__title {
    border-bottom: $border_thickness solid $grey_rule;
    padding-bottom: 5px;
    margin-bottom: 20px;
  }

  &__info {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    @include mobile-screens-up {
      margin-bottom: 60px;
    }

    p:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  &__address {
    a {
      opacity: .5;
      transition: opacity .4s ease;

      &[target="_blank"]::after {
        content: " ↗";
      }
      
      &:hover {
        opacity: 1;
      }
    }
  }
}