.card {
  display: block;

  &__media {
    margin-bottom: 10px;

    img,
    video {
      transition: opacity .6s ease;
    }
  }

  &__title {
    font-weight: 500;
  }

  &__tagline {
    margin: 0;
  }

  &__description {
    margin-top: 0.25em;

    @include mobile-screens-up {
      margin-top: 0.75em;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;

    blockquote {
      padding: 30px;
    }

    &.b2 {
      .card__title {
        order: 1;
      }

      .card__tagline {
        order: 2;
      }
    }
  }

  &:not(&--studio):hover {
    cursor: pointer;

    h4 {
      text-decoration: underline;
      text-decoration-thickness: $border_thickness;
      text-underline-offset: 3px;
    }
  }

  &--article {
    .card__description {
      margin-bottom: 1em;
    }

    .card__title {
      margin-bottom: 0.5em;
    }
  }

  &--studio {
    .card__text {
      padding: 0 25px;
    }

    .card__media {
      @include mobile-screens-down {
        width: calc(100vw - 160px);
        margin: 0 auto 20px;
      }

      @include mobile-screens-up {
        margin-bottom: 60px;
      }
    }
  }
}