.article-pullquote {
  padding: 20px 20px;

  @include mobile-screens-up {
    padding: 60px 20px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 20px;

    & > div {
      grid-column: 3 / span 6;
    }
  }

  @include tablet-screens-up {
    padding: 120px 0;
  }

  blockquote {
    text-indent: -0.4em;
  }
}