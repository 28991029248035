.caseStudy {
  main {
    padding-top: 0;
  }

  header {
    & > div {
      @include tablet-screens-up {
        border-bottom: 1px solid transparent;
      }
    }
  }

  .section-block--has-title {
    .rich-text:first-of-type .rich-text__content.desktop-position-center {
      padding: 20px 0 60px;

      @include mobile-screens-up {
        padding: 60px 0 120px;
  
        &.text-size-h1 {
          padding: get-vw(1512, 250px) 0;
        }
      } 
    }
  }
}