.media-banner {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    img,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--has-overlay {
      background-color: #000;

      img,
      video {
        opacity: 0.9;
      }
    }
  }

  &__text {
    text-align: center;
    color: $white;
    z-index: 1;

    h2 {
      margin-bottom: 35px;
      line-height: 0.95em;
    }

    p {
      margin-bottom: 0;
    }
  }

  @include mobile-screens-up {
    &--inset {
      .media-banner__media {
        margin: 20px;
        width: calc(100% - 40px);
        height: 100%;
      }
  
      &.media-banner--adjust-to-media {
        .media-banner__media {
          height: auto;
        } 
      }
    }
  }

  &--adjust-to-media {
    height: auto;

    .media-banner__media {
      position: static;
    }

    @include mobile-screens-down {
      margin-bottom: 20px;
    }
  }

  &--hero {
    height: calc(100vh - 160px);

    @include mobile-screens-up {
      height: calc(100vh - 58px);
    }

    @include desktop-screens-up {
      height: calc(100vh - 68px);
    }

    .media-banner__text {
      pointer-events: none;
    }
  }

  &--narrow {
    height: 328px;

    @include mobile-screens-down {
      height: 40vh;
    }
  }
}