.media-with-text {
  padding: 0 10px;

  @include mobile-screens-down {
    padding: 0 10px 0;
    margin-bottom: 20px;
  }

  .flex {
    @include mobile-screens-down {
      display: block;
    }

    & > div {
      padding: 0 10px;
    }

    &:not(&--reverse) {
      .media-with-text__text__inner {
        @include mobile-screens-up {
          padding: 0 20px 0 0;
        }
      }
    }
  
    &--reverse {
      .media-with-text__text__inner:not(.no-padding) {
        @include mobile-screens-up {
          padding: 0 40px;
        }
      }
    }
  }

  &__text {
    @include mobile-screens-down {
      margin: 0 0 20px;
    }
  }

  &__media {
    @include mobile-screens-down {
      &:not(:empty) {
        margin: 0 0 0;
      }
    }

    &__svg-overlay {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-20%, -70%);
      width: 214px;
      height: 250px;
      z-index: 1;
    }
  }
}