.filter {
  padding: 20px 20px 60px;

  @include mobile-screens-down {
    line-height: 1.3em;
  }

  @include mobile-screens-up {
    display: grid;
    padding: 20px 20px 130px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1;
    gap: 10px;
  }

  @include tablet-screens-up {
    grid-template-columns: repeat(6, 1fr);
    padding: 40px 20px 130px;
  }

  &__search {
    grid-column: 5 / span 2;
    grid-row: 1;

    @include tablet-screens-down {
      display: none;
    }

    input {
      -webkit-appearance: none;
      outline: 0;
      border: 0;
      border-bottom: $border_thickness solid $black;
      padding: 0;
      padding-bottom: 10px;
      width: 100%;
      background-color: transparent;
      color: $black;
      @extend .c1;
      font-family: $font_sans;

      &::placeholder {
        color: $black;
      }
    }

    form {
      margin-bottom: 1em;
    }
  }

  &__items {
    position: relative;
    grid-column: 1 / span 4;
    grid-row: 1;

    @include tablet-screens-up {
      grid-column: 1 / span 3;
    }
  }

  &__item {
    &__title {
      display: inline;
      position: relative;
      z-index: 10;
      text-decoration: none;
      transition: all .4s ease;
      
      .no-touchevents &:hover {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-thickness: $border_thickness;
        text-underline-offset: 3px;
      }

      span {
        display: none;
      }
    }

    & > div {
      display: none;
      width: 100%;
      
      @include mobile-screens-down {
        padding-left: 2em;
        box-sizing: border-box;
      }

      @include mobile-screens-up {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 10px;
      }

      @include tablet-screens-up {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &.is-active {
      .filter__item__title {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-thickness: $border_thickness;
        text-underline-offset: 3px;

        @include tablet-screens-down {
          text-decoration: none;

          span {
            display: inline;
          }
        }
      }

      & > div {
        display: block;

        @include mobile-screens-up {
          display: grid;
        }
      }
    }
  }

  &__sub-items {
    @include mobile-screens-up {
      grid-column: 3 / span 2;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-flow: column;
      grid-template-rows: repeat(6, auto);
      column-gap: 10px;
    }

    @include tablet-screens-up {
      grid-column: 2 / span 2;
    }

    .link {
      transition: opacity .4s ease;
    }
  }

  &.is-active {
    .filter__item:not(.is-active) > .filter__item__title:not(:hover) {
      opacity: .35;
    }
  }
}