.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 8888;

  @include mobile-screens-down {
    top: -1px;
  }

  & > div {
    height: 53px;
    box-sizing: border-box;
    display: flex;

    @include tablet-screens-down {
      height: 100%;
      flex-direction: column;
    }

    @include tablet-screens-up {
      align-items: center;
      justify-content: space-between;
      border-bottom: $border_thickness solid $black;
    }
  }

  &__logo {
    font-size: 0;

    @include tablet-screens-down {
      padding: 0 20px;
      height: 50px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      pointer-events: all;
      background-color: rgba(250, 250, 248, 0.4);
      backdrop-filter: blur(20px);
    }

    @include tablet-screens-only {
      body:not(.home):not(.caseStudy):not(.opinionArticle) & {
        justify-content: space-between;

        &::after {
          content: "";
          width: 100%;
          height: 1px;
          background-color: $black;
          display: block;
        }
      }
    }

    a {
      display: block;
    }
    
    svg {
      width: 125px;

      @include tablet-screens-down {
        width: 100px;
      }

      @include mobile-screens-down {
        width: 90px;
      }
    }
  }

  @include tablet-screens-down {
    height: 100%;
    pointer-events: none;

    &.nav-is-open {
      background-color: rgba(250, 250, 248, 0.5);
      backdrop-filter: blur(20px);

      .header__logo {
        background-color: transparent;
        backdrop-filter: unset;
      }
    }
  }

  @include mobile-screens-down {
    height: calc(100% + 1px);
  }

  @include tablet-screens-up {
    background-color: rgba(250, 250, 248, 0.5);
    backdrop-filter: blur(20px);
    padding: 0 20px;
  }
}