// Breakpoints
@mixin large-screens-up {
  @media (min-width: #{$breakpoint_XL}px) {
    @content;
  }
}

@mixin desktop-screens-up {
  @media (min-width: #{$breakpoint_L}px) {
    @content;
  }
}

@mixin tablet-screens-up {
  @media (min-width: #{$breakpoint_M}px) {
    @content;
  }
}

@mixin tablet-screens-only {
  @media (min-width: #{$breakpoint_S}px) and (max-width: #{$breakpoint_M - 1}px) {
    @content;
  }
}

@mixin tablet-screens-down {
  @media (max-width: #{$breakpoint_M - 1}px) {
    @content;
  }
}

@mixin mobile-screens-up {
  @media (min-width: #{$breakpoint_S}px) {
    @content;
  }
}

@mixin mobile-screens-down {
  @media (max-width: #{$breakpoint_S - 1}px) {
    @content;
  }
}

@mixin portrait {
  @media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 2) 
  and (orientation: portrait) {
    @content;
  }
}

