.process {
  padding: 30px 20px 0;
  border-top: $border_thickness solid rgba(0,0,0,0.35);

  @include tablet-screens-up {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
  }

  blockquote {
    box-sizing: border-box;
    padding: 0 0 0 60px;
    margin-bottom: 1em;
    text-indent: -0.4em;
    quotes: "\201C""\201D""\2018""\2019";

    &::before {
      content: open-quote;
    }

    &::after {
      content: close-quote;
    }

    p,
    strong {
      display: inline;
    }
  }

  code {
    padding: 0 0 0 60px;
  }

  h2 {
    grid-column: 2 / span 5;
    margin-bottom: 60px;
  }

  &__content {
    grid-column: 2 / span 3;

    @include tablet-screens-down {
      .button {
        width: calc(100% - 80px);
        margin: 30px auto 0;
      }
    }
  }

  &__text {
    margin-bottom: 80px;

    @include tablet-screens-up {
      margin-bottom: 80px;
    }
  }

  &__ctas {
    grid-column: 5 / span 2;
  }

  &__cta {
    margin-bottom: 80px;

    .cta-card {
      width: 100%;
      padding-left: 100px;
      box-sizing: border-box;
    }
  }
}