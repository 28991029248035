.page-cart {
    padding: 0 20px 150px 20px;

    h3 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 30px;
        margin-top: 30px;
    }

    .product__checkout-button {
        padding: 5px 50px;
        border-radius: 50px;
        font-size: 16px;
        background-color: $black;
        color: $white;
        border: 0;
        outline: none;

        &:hover {
            cursor: pointer;
        }
    }

    .product__checkout-button-wrapper,
    .product__paynow-button-wrapper {
        clear: both;

        &:after {
            content: " ";
            display: block;
            clear: both;
        }
    }

    .text-center {
        text-align: center;
    }
      
    .text-right {
        text-align: right;
    }

    input[type="text"],
    input[type="email"],
    select {
        appearance: none;
        margin: 0;
        padding: 2px 0;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        background-color: rgba(0, 0, 0, 0.025);
        
        &:focus {
            outline: none;
            background-color: rgba(0, 0, 0, 0.05);
        }
    }

    select {
        background-image: url('data:image/svg+xml;utf8,<svg fill="#666" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 10"><path d="M0 0l7 10 7-10z"/></svg>');
        background-size: 24px 10px;
        background-repeat: no-repeat;
        background-position: right center;
    }

    .form-group {
        margin-bottom: 10px;

        &:after {
            content: " ";
            display: block;
            clear: both;
        }

        &--double-spacer {
            margin-bottom: 40px;
        }

        &.has-error {
            color: red;
        }

        label {
            display: block;

            @include mobile-screens-down {
                font-size: 16px;
            }

            @include mobile-screens-up {
                display: inline-block;
                width: calc(50% - 20px);
            }

            @include tablet-screens-down {
                display: inline-block;
                width: 40%;
            }
        }

        input,
        select {
            width: 100%;

            @include mobile-screens-up {
                width: calc(50% - 20px);
                float: right;
            }

            @include tablet-screens-down {
                width: 50%;
            }
        }

        select {
            -webkit-appearance: none;
        }
    }

    .cart-summary {
        margin-bottom: 40px;

        table {
            width: 100%;
        }

        table,
        input {
            @include mobile-screens-down {
                font-size: 16px;
            }
        }

        th,
        td {
            padding: 20px 0;
        }

        th {
            padding-top: 0;
            opacity: 0.25;
        }

        td {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
        }

        a {
            display: table;
            height: 100%;

            img,
            strong {
                display: table-cell;
                vertical-align: middle;
            }

            img {
                width: 100px;
                height: auto;
                margin-right: 20px;

                @include mobile-screens-down {
                display: none;
                }
            }
        }
      
        .cart-summary__item-name {
            width: 70%;
            text-align: left;
        }

        .cart-summary__price {
            text-align: left;

            @include mobile-screens-down {
                display: none;
            }
        }

        .cart-summary__qty input[type="number"] {
            -webkit-appearance: none;
            border: none;
            background-color: transparent;
            width: 40px;
            text-align: center;

            &:focus {
                outline: none;
                background-color: rgba(0, 0, 0, 0.05);
            }
        }
    }

    .form-group--primary-contact {
        @include mobile-screens-up {
          width: calc(50% - 20px);
        }

        @include tablet-screens-up {
          width: calc(50% - 40px);
        }
    }

    .checkout__address {
        @include mobile-screens-up {
            width: calc(50% - 20px);

            &--shipping {
                float: left;
            }

            &--billing {
                float: right;
            }
        }

        @include tablet-screens-up {
            width: calc(50% - 40px);
        }

        &__same-address {
            clear: both;
        }

        &.is-hidden {
            display: none;
        }
    }

    .address-select {
        margin-bottom: 40px;

        .radio {
            margin-bottom: 10px;
        }

        .edit {
            margin-top: 5px;
            display: inline-block;
            background-color: #ccc;
            padding: 3px;
        }
    }

    .checkout__address__same-address {
        padding: 10px 0 20px 0;

        &:after {
            content: " ";
            display: block;
            clear: both;
        }

        @include mobile-screens-down {
            width: calc(50% - 20px);
            float: right;
        }

        @include mobile-screens-up {
            float: left;
        }

        @include tablet-screens-up {
            width: calc(50% - 40px);
        }

        &--e {
            font-size: 16px;
            width: 100%;

            @include mobile-screens-up {
                float: right;
            }
        }
    }
    
    .form-control--complex {
        margin-bottom: 20px;

        &:after {
            content: " ";
            display: block;
            clear: both;
        }
        
        @include mobile-screens-up {
            margin-bottom: 10px;
        }

        &__label {
            @include mobile-screens-up {
                float: left;
                width: 33.3%;
            }
        }

        &__fields {
            @include mobile-screens-up {
                float: right;
                width: 66.6%;
            }
        }
    }

    .order-addresses {
        &:after {
            content: " ";
            display: block;
            clear: both;
        }
    }

    .order-addresses__address {
        margin-bottom: 40px;
        
        @include mobile-screens-up {
          float: left;
          width: 50%;
        }

        @include desktop-screens-up {
          width: 33.33%;
        }
    }
}