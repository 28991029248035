.job-listings {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 20px 0;

  @include mobile-screens-up {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 20px;
    padding: 60px 20px 20px;
  }

  hr {
    grid-column: 1 / span 6;
    margin: 20px 0;
    background-color: $grey_rule;

    @include mobile-screens-up {
      margin: 7px 0;
    }
  }

  &__header {
    display: contents;

    h6 {
      &:first-child {
        grid-column: 1;
      }

      &:nth-child(2) {
        grid-column: 4;

        @include mobile-screens-down {
          display: none;
        }
      }
    }

    hr {
      @include mobile-screens-down {
        margin-top: 10px;
      }
    }
  }

  &__content {
    display: contents;
  }

  .job-listing {
    display: contents;

    & > a {
      display: contents;

      &:hover {
        cursor: pointer;
        
        .h4 span {
          text-decoration: underline;
          text-decoration-thickness: $border_thickness;
          text-underline-offset: 3px;
        }
      }
    }

    &__title {
      grid-column: 1 / span 3;
      
      @include mobile-screens-up {
        margin-top: 5px;
      }

      span {
        display: inline-block;
        margin-right: 5px;
      }
    }

    &__description {
      grid-column: 4 / span 2;
      margin-bottom: 5px;

      @include mobile-screens-down {
        margin-top: 10px;
      }
    }

    &:last-child {
      hr {
        display: none;
      }
    }
  }
}