.nav-button {
  -webkit-appearance: none;
  transition: transform 0.3s ease;
  background-color: transparent;
  border: 0;
  outline: 0;
  height: 50px;
  padding: 0 20px;
  position: absolute;
  right: 0;
  pointer-events: all;

  @include tablet-screens-up {
    display: none;
  }

  &__inner {
    width: 24px;
    height: 24px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    width: 24px;
    height: 2px;
    background-color: $black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
    transform-origin: 50%;

    &:nth-child(2) {
      transform: translate(-50%, -8px);
    }

    &:last-child {
      transform: translate(-50%, 6px);
    }
  }

  .nav-is-open & {
    span {
      transform: translate(-50%, -50%) rotate(-45deg);
  
      &:nth-child(2) {
        transform: translate(-50%, -50%) rotate(45deg);
      }
  
      &:last-child {
        opacity: 0;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}