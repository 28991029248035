.our-values {
  padding: 60px 0;
  background-color: #F0FCFF;

  @include mobile-screens-down {
    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 20px;
  }

  &__items {
    @include mobile-screens-down {
      width: calc(300vw - 80px);
    } 
  }
}