.media-slider {
  padding: 0 0 30px 0;

  @include mobile-screens-down {
    margin-bottom: 20px;
  }

  @include tablet-screens-up {
    padding: 0 0 0 20px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    align-items: stretch;
  }

  @media (min-width: 990px) and (min-height: 600px) {
    height: 850px;
  }

  @media (min-width: 990px) and (min-height: 800px) {
    height: calc(100vh - 53px);
  }

  &__text {
    grid-column: 1 / span 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__inner {
      padding: 30px 20px;
      box-sizing: border-box;
      max-width: 600px;

      @include tablet-screens-up {
        padding: 40px 30px 30px 0;
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      transition: all .4s ease;

      &:hover {
        background-color: $black;
        color: $white;
      }

      @include tablet-screens-down {
        display: none;
      }

      @include tablet-screens-up {
        height: 60px;
        border-top: $border_thickness solid $default_background;
        margin: 0 -20px;
      }
    }

    .media-slider__pagination-wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__pagination {
    position: relative;
    padding: 20px;

    @include tablet-screens-up {
      padding: 20px 20px 40px;
    }
  }

  &__items {
    grid-column: span 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include tablet-screens-up {
      padding: 40px 0 0 0;
      border-left: $border_thickness solid $default_background;
    }

    &__pagination {
      display: flex;
      align-items: center;
      justify-content: left;
      height: 40px;
      padding: 0 20px;

      @include tablet-screens-up {
        height: 60px;
      }
    }
  }
}